import Raven from 'raven-js';

import config from '../../config';

const {
  ENVIRONMENT,
  REACT_APP_SENTRY_API_KEY,
  REACT_APP_SENTRY_PROJECT_ID,
} = config;

const sentryUrl = `https://${REACT_APP_SENTRY_API_KEY}@sentry.io/${REACT_APP_SENTRY_PROJECT_ID}`;

Raven.config(sentryUrl, { environment: ENVIRONMENT }).install();
