import React from 'react';
import { TableCell } from '@material-ui/core';
import { elements, colors } from '@peachjar/components';

const { mercury } = colors;
const { Note } = elements.typography;

type Props = {
  pillText: string;
  pillColor?: string;
  className?: string;
  textColor?: string;
  innerClassName?: string;
};

const StatusPill = ({ pillText, pillColor, textColor, className, innerClassName }: Props) => (
  <div
    className={className}
    style={{
      backgroundColor: pillColor || mercury,
      borderRadius: '10px',
      height: '17px',
      maxWidth: '76px',
      padding: '0 8px',
      textAlign: 'center',
      marginLeft: '8px',
    }}
  >
    <Note style={{ color: textColor }} className={innerClassName}>{pillText}</Note>
  </div>
);

export default StatusPill;
